import axios from 'axios';
import qs from 'qs';
import Services from './services';

function paramSerialise(params) {
    return qs.stringify(params, {
        arrayFormat: 'brackets',
        encode: false,
    });
}

export default class NetService {
    static prepareUri(uri, params) {
        const re = /{([\w\-]+)}/g;
        let match = re.exec(uri);
        const keys = new Set();

        while (match) {
            keys.add(match[1]);
            match = re.exec(uri);
        }

        for (const key of keys) {
            uri = uri.replace(new RegExp(`\{${key}\}`), params[key]);
            delete params[key];
        }

        return { uri, params };
    }

    params(method, uri, params = {}, data = {}, options = {}) {
        const store = Services.store();
        const { uri: newUri, params: newParams } = NetService.prepareUri(uri, params);
        const config = Object.assign(
            {
                url: newUri,
                method,
                params: newParams,
                headers: {
                    Accept: 'application/json',
                },
                paramsSerializer: paramSerialise,
            },
            options
        );

        if (['post', 'put'].includes(method)) {
            if (data instanceof FormData) {
                config.data = data;
            } else {
                config.headers['Content-Type'] = 'application/json';
                config.data = JSON.stringify(data);
            }
        }

        if (store.state.env.token) {
            config.headers.Authorization = `Bearer ${store.state.env.token}`;
        }

        return config;
    }

    get(uri, params = {}, options = {}, disableErrorAlert = false) {
        return this.request(this.params('get', uri, params, {}, options), disableErrorAlert);
    }

    post(uri, params = {}, data = {}, options = {}, disableErrorAlert = false) {
        return this.request(this.params('post', uri, params, data, options), disableErrorAlert);
    }

    put(uri, params = {}, data = {}, options = {}, disableErrorAlert = false) {
        return this.request(this.params('put', uri, params, data, options), disableErrorAlert);
    }

    delete(uri, params = {}, options = {}, disableErrorAlert = false) {
        return this.request(this.params('delete', uri, params, {}, options), disableErrorAlert);
    }

    request(params, disableErrorAlert = false) {
        return axios
            .request(params)
            .then(resp => {
                if (resp.status >= 200 && resp.status < 300) {
                    return resp.data;
                }
            })
            .catch(error => {
                let errorMsg = '';
                if (error.response) {
                    errorMsg = error.response.data.message;

                    if (!errorMsg && error.response.status === 413) {
                        errorMsg = 'Слишком большой файл';
                    }

                    if (!errorMsg && error.response.status === 424) {
                        errorMsg = 'Нельзя удалить товарную группу, пока в ней содержатся товары';
                    }
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }

                if (!disableErrorAlert && errorMsg) {
                    Services.msg(errorMsg, 'danger');
                }

                return Promise.reject(errorMsg);
            });
    }
}
