const fileUrl = process.env.FILE_MS_URL;
export default class Media {
    static compressed(imageId, w, h, type = 'jpg') {
        return `${fileUrl}content/${imageId}/${w}/${h}/${type}`;
    }

    static empty(w, h, text = 'No image') {
        return `//placehold.it/${w}x${h}?text=${encodeURI(text)}`;
    }

    static video(code) {
        return `https://www.youtube.com/embed/${code}`;
    }

    static file(id) {
        return `${fileUrl}content/${id}`;
    }
}