<template>
  <div class="Header-layout">
        <header class="navbar navbar-dark bg-dark" style="color: #dfdfdf;">
            <div class="d-flex">
                <b-navbar-brand href="/" title="Платформа">
                    <picture>
                        <source srcset="/assets/images/logo_new.webp" type="image/webp">
                        <img src="/assets/images/logo_new.png" width="167" height="64">
                    </picture>
                </b-navbar-brand>
                <span class="d-none d-md-block">
                    <span style="
                        font-size: 256%;
                        line-height: 40px;
                        margin-right: 10px;
                        transform: scaleX(0.5);
                    ">|</span>
                    <span style="color:white">
                        Administration System
                    </span>
                </span>
            </div>

            <div v-if="!user.isGuest" class="d-flex">
                <notifications/>

                <communication-chats-unread/>

                <button @click="logout" class="btn btn-dark">Выйти</button>
            </div>
        </header>

    <div class="Header-menu" v-if="!user.isGuest">
        <MainMenu></MainMenu>
    </div>
  </div>
</template>

<script>
    import '../../../../images/logo_new.png';
    import Services from '../../../../scripts/services/services';
    import modalMixin from '../../../mixins/modal.js';
    import MainMenu from '../../main-menu/main-menu.vue';
    import CommunicationChatsUnread from './communication-chats-unread.vue';
    import Notifications from './notifications.vue';

    export default {
    name: 'layout-header',
    components: {Notifications, CommunicationChatsUnread, MainMenu},
    mixins: [modalMixin],
    props: {
        onIndex: { type: Boolean, default: false },
    },
    data() {
        return {};
    },
    methods: {
        logout() {
            Services.net().post(this.route('logout'))
                .then(() => {
                    window.location.href = this.route('page.login');
                }, () => {
                    this.showMessageBox({title: 'Ошибка', text: 'Произошла ошибка. Попробуйте позже.'})
                })
        }
    },
};
</script>
<style>

.Header-layout .only-mobile
{
  display: none;
}

.Header-layout .only-desktop
{
  display: block;
}

@media screen and (max-width: 1023px)
{

  .Header-layout .only-mobile
  {
    display: block;
  }

  .Header-layout .only-desktop
  {
    display: none;
  }
}

.Header
{
  position: relative;
  background-color: #141116;
  height: 80px;
  color: #fff;
  font-size: 14px;
  line-height: 16px;
}

.Header a, .Header a:hover
{
  color: #fff;
}

.small
{
  color: #7d7d7d;
  font-size: 12px;
}

.Header-logo
{
  text-align: center;
  height: 40px;
  line-height: 80px;
}

.Header-logo img
{
  height: 100%;
}

.Header-logo .large
{
  display: auto;
}

.Header-logo .small
{
  display: none;
}

@media screen and (max-width: 1290px)
{
  .Header-logo .large
  {
    display: none;
  }

  .Header-logo .small
  {
    display: inline-block;
  }
}

.Header-tools
{
  position: absolute;
  top: 0;
  display: flex;
  height: 80px;
  align-items: center;
}

.Header-tools .only-mobile
{
  display: none;
}

.Header-tools .only-desktop
{
  display: inline-block;
}

@media screen and (max-width: 1023px)
{

  .Header, .Header-logo, .Header-tools
  {
    height: 48px;
  }

  .Header-logo
  {
    line-height: 48px;
    height: 29px;
  }

  .Header-tools .only-mobile
  {
    display: inline-block;
  }

  .Header-tools .only-desktop
  {
    display: none;
  }
}

.Header-tools > div
{
  width: 50vw;
}

.Header-menuBtn {
  cursor: pointer;
  padding-left: 16px;
}

.Header-rightBar
{
  text-align: right;
  padding-right: 41px;
}

.Header-rightBar > div
{
  display: inline-block;
  padding-left: 28px;
}

@media screen and (max-width: 1023px)
{
  .Header-rightBar
  {
    padding-right: 16px;
  }

  .Header-rightBar > div
  {
    display: inline-block;
    padding-left: 22px;
  }
}

</style>
