import Vuex from 'vuex';
import Vue from 'vue';
import NetService from '../../scripts/services/net';

import ModalModule from './modules/modal.js';
import massSelection from './modules/mass-selection.js';

import products from './modules/products.js';
import returnProducts from './modules/return_products.js';
import brands from './modules/brands.js';
import organizers from './modules/organizers.js';
import publicEvents from './modules/public-events.js';
import places from './modules/places.js';
import speakers from './modules/speakers.js';
import types from './modules/types.js';
import serviceNotifications from './modules/service-notifications.js';
import Services from '../../scripts/services/services';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        title: '',
        layout: {},
        env: {},
        routes: {},
        loaderShow: false,
        mode: '',
    },
    modules: {
        modal: ModalModule(),
        massSelection,
        products,
        returnProducts,
        brands,
        places,
        publicEvents,
        organizers,
        speakers,
        types,
        serviceNotifications,
    },
    mutations: {
        loaderShow(state, loaderShow) {
            state.loaderShow = loaderShow;
        },
        title(state, data) {
            state.title = data;
            document.title = data;
        },
        env(state, data) {
            state.env = data;
        },
        layout(state, data) {
            state.layout = data;
        },
        routes(state, routes) {
            state.routes = routes;
        },
        setMode(state, data) {
            state.mode = data;
        },
    },
    getters: {
        getRoute: state => (name, params = {}) => {
            const r = state.routes[name];
            if (!r) return;
            const { uri } = NetService.prepareUri(r, params);
            return `/${uri.replace(/^\//, '')}`;
        },
        isReadMode: state => state.mode === 'READ',
    },
    actions: {
        async requestPermissions({ commit, getters }) {
            const route = getters.getRoute('permissions.check');
            const service = Services.net();

            await service.get(route).then(response => {
                if (!response || !response.mode) {
                    return;
                }

                commit('setMode', response.mode);
            });
        },
    },
});
